import "./index.scss";

import {
    APPLICATION_TITLE,
    COOKIE_NAME,
    EMAIL_CONFIRMATION_MESSAGE,
    FORGOT_PWD_ACCOUNT_REQUEST,
    JWT_VALUE_IN_COOKIE,
    LOGIN_REQUEST,
    LOGOUT_MESSAGE,
    LOGOUT_REQUEST,
    NEW_PWD_ACCOUNT_REQUEST,
    PASSWORD_CHANGE_SUCCESS_MESSAGE,
    PWD_NO_MATCH,
    REGISTER_REQUEST,
    RESET_PASSWORD_MESSAGE
} from "../../tools";

import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {jwtDecode} from 'jwt-decode';
import {useAccount} from '../../AccountContext';

import Cookies from "js-cookie";
import axios from "axios";

function MyAccount() {
    const { accountData, updateAccountData, isConnected } = useAccount();
    const [isLoggedIn, setIsLoggedIn] = useState(accountData !== null);

    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [showForgotPwdEmailForm, setShowForgotPwdEmailForm] = useState(false);
    const [showForgotPwdResetForm, setShowForgotPwdResetForm] = useState(false);

    const [emailLogin, setEmailLogin] = useState('');
    const [passwordLogin, setPasswordLogin] = useState('');
    const [firstNameRegister, setFirstNameRegister] = useState('');
    const [lastNameRegister, setLastNameRegister] = useState('');
    const [emailRegister, setEmailRegister] = useState('');
    const [passwordRegister, setPasswordRegister] = useState('');
    const [confirmPasswordRegister, setConfirmPasswordRegister] = useState('');
    const [emailForgotPwd, setEmailForgotPwd] = useState('');
    const [tokenForgotPwd, setTokenForgotPwd] = useState('');
    const [passwordForgotPwd, setPasswordForgotPwd] = useState('');
    const [confirmPasswordForgotPwd, setConfirmPasswordForgotPwd] = useState('');

    const navigate = useNavigate();

   useEffect(() => {
       if (accountData === null) {
           const jwt = Cookies.get(COOKIE_NAME);
           if (jwt !== undefined) {
               updateAccountData(jwtDecode(jwt));
               setIsLoggedIn(true);
           }
       }
   }, [accountData]);

    const navigateAccountToProfile = () => {
        navigate("/blogs");
    };

    const cleanLoginFrom = () => {
        setShowLoginForm(false);
        setEmailLogin('');
        setPasswordLogin('');
    }

    const cleanRegisterForm = () => {
        setShowRegisterForm(false);
        setFirstNameRegister('');
        setLastNameRegister('');
        setEmailRegister('');
        setPasswordRegister('');
        setConfirmPasswordRegister('');
    }

    const cleanForgotPwdForms = () => {
        setShowForgotPwdEmailForm(false);
        setShowForgotPwdResetForm(false);
        setEmailForgotPwd('');
        setTokenForgotPwd('');
        setPasswordForgotPwd('');
        setConfirmPasswordForgotPwd('');
    }

    const handleLoginClick = () => {
        setShowLoginForm(true);
        cleanRegisterForm();
        cleanForgotPwdForms();
    };

    const handleRegisterClick = () => {
        setShowRegisterForm(true);
        cleanLoginFrom();
        cleanForgotPwdForms();
    };

    const handleShowForgotPwdEmailForm = () => {
        setShowForgotPwdEmailForm(true);
        cleanLoginFrom();
        cleanRegisterForm();
    }

    const handleForgotPwdEmailFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(FORGOT_PWD_ACCOUNT_REQUEST, emailForgotPwd, {
                headers: {
                    'Content-Type': 'text/plain'
                }});
            alert(RESET_PASSWORD_MESSAGE);
            setShowForgotPwdEmailForm(false);
            setShowForgotPwdResetForm(true);
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                alert(error.response.data);
            } else {
                console.error(error);
            }
        }
    }

    const handleForgotPwdResetFormSubmit = async (e) => {
        e.preventDefault();
        if (passwordForgotPwd !== confirmPasswordForgotPwd) {
            alert(PWD_NO_MATCH);
            setPasswordForgotPwd('');
            setConfirmPasswordForgotPwd('');
            return;
        }
        try {
            await axios.patch(NEW_PWD_ACCOUNT_REQUEST, {
                email: emailForgotPwd,
                token: tokenForgotPwd,
                newPassword: passwordForgotPwd
            })
            alert(PASSWORD_CHANGE_SUCCESS_MESSAGE);
            handleLoginClick()
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                alert(error.response.data);
            } else {
                console.error(error);
            }
        }
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_REQUEST, {
                email: emailLogin,
                password: passwordLogin
            });

            const token = response.data;
            Cookies.set(COOKIE_NAME, token);
            updateAccountData(jwtDecode(token));
            setIsLoggedIn(true);

            window.location.reload();
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                alert(error.response.data);
            } else {
                console.error(error);
            }
        }

        setEmailLogin('');
        setPasswordLogin('');
    };

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        if (passwordRegister !== confirmPasswordRegister) {
            alert(PWD_NO_MATCH);
            setPasswordRegister('');
            setConfirmPasswordRegister('');
            return;
        }

        try {
            await axios.post(REGISTER_REQUEST, {
                firstName: firstNameRegister,
                lastName: lastNameRegister,
                email: emailRegister,
                password: passwordRegister
            });
            alert(EMAIL_CONFIRMATION_MESSAGE);

            handleLoginClick();
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                alert(error.response.data);
            } else {
                console.error(error);
            }
        }

        setFirstNameRegister('');
        setLastNameRegister('');
        setEmailRegister('');
        setPasswordRegister('');
        setConfirmPasswordRegister('');
    };

    async function handleLogout() {
        const confirmLogout = window.confirm(LOGOUT_MESSAGE);
        if (confirmLogout) {
            const jwt = Cookies.get(COOKIE_NAME);
            if (jwt !== undefined) {
                try {
                    await axios.post(LOGOUT_REQUEST, {},
                        {
                            headers: {
                                "account_session": jwt
                            }
                        });
                } catch (error) {
                    if (error.response && error.response.status !== 200) {
                        alert(error.response.data);
                    } else {
                        console.error(error);
                    }
                }

                Cookies.remove(COOKIE_NAME);
                updateAccountData(null);
                setIsLoggedIn(false);
                window.location.reload();
            } else {
                alert("Logout issue: " + JWT_VALUE_IN_COOKIE);
            }
        }
    }

    return (
        <div className="my-account-container">
            <h1>{APPLICATION_TITLE}</h1>
            { !isLoggedIn ? (
                <>
                    { !showLoginForm && (
                        <button onClick={handleLoginClick}>Login</button>
                    )}
                    { !showRegisterForm && (
                        <button onClick={handleRegisterClick}>Register</button>
                    )}

                    { showLoginForm && (
                        <form onSubmit={handleLoginSubmit}>
                            <br/>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={emailLogin}
                                    onChange={(e) => setEmailLogin(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="password">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={passwordLogin}
                                    onChange={(e) => setPasswordLogin(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit">Login</button>
                            <text onClick={handleShowForgotPwdEmailForm} className="forgot-pwd-btn">forgot password ?</text>
                        </form>
                    )}

                    { showRegisterForm && (
                        <form onSubmit={handleRegisterSubmit}>
                            <br/>
                            <div>
                                <label htmlFor="firstName">First Name:</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    value={firstNameRegister}
                                    onChange={(e) => setFirstNameRegister(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="lastName">Last Name:</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    value={lastNameRegister}
                                    onChange={(e) => setLastNameRegister(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={emailRegister}
                                    onChange={(e) => setEmailRegister(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="password">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={passwordRegister}
                                    onChange={(e) => setPasswordRegister(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="confirmPassword">Confirm Password:</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    value={confirmPasswordRegister}
                                    onChange={(e) => setConfirmPasswordRegister(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit">Register</button>
                        </form>
                    )}

                    { showForgotPwdEmailForm && (
                        <form onSubmit={handleForgotPwdEmailFormSubmit}>
                            <text className="forgot-pwd-btn">forgot password ?</text>
                            <br/>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="text"
                                    id="email"
                                    value={emailForgotPwd}
                                    onChange={(e) => setEmailForgotPwd(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit">Submit</button>
                        </form>
                    )}

                    { showForgotPwdResetForm && (
                        <form onSubmit={handleForgotPwdResetFormSubmit}>
                            <text className="forgot-pwd-btn">forgot password ?</text>
                            <br/>
                            <div>
                                <label htmlFor="email">Token:</label>
                                <input
                                    type="text"
                                    id="token"
                                    value={tokenForgotPwd}
                                    onChange={(e) => setTokenForgotPwd(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="password">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={passwordForgotPwd}
                                    onChange={(e) => setPasswordForgotPwd(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="confirmPassword">Confirm Password:</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    value={confirmPasswordForgotPwd}
                                    onChange={(e) => setConfirmPasswordForgotPwd(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit">Submit</button>
                        </form>
                    )}
                </>
            ) : (
                <>
                    <button onClick={navigateAccountToProfile}>AccountId: {accountData.id}</button>
                    <p>FirstName: {accountData.firstName}</p>
                    <p>LastName: {accountData.lastName}</p>

                    <div className="logout-container">
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default MyAccount;
