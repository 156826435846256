import "./index.scss";

import Post from "../Post";
import {ALL_POSTS_REQUEST, EVENT_SOURCE_POSTS_REQUEST} from "../../tools";

import React, {useEffect, useState} from "react";

import axios from "axios";

const Blog = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            try {
                const response = await axios.get(ALL_POSTS_REQUEST);
                setPosts(response.data);
            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    alert(error.response.data);
                } else {
                    console.error(error);
                }
            }
        };

        getPosts().then(r => {});

        const eventSource = new EventSource(EVENT_SOURCE_POSTS_REQUEST);

        eventSource.addEventListener("newPost", (event) => {
            const newPost = JSON.parse(event.data);
            setPosts((prevPosts) => [...prevPosts, newPost]);
        });

        eventSource.addEventListener("removePost", (event) => {
            const removedPost = JSON.parse(event.data);
            setPosts((prevPosts) => prevPosts.filter(post => post.id !== removedPost.id));
        });

        eventSource.addEventListener("updatePost", (event) => {
            const updatedPost = JSON.parse(event.data);
            setPosts((prevPosts) => prevPosts.map(post => post.id === updatedPost.id ? updatedPost : post));
        });

        return () => {
            eventSource.close();
        };
    }, []);

    return (
        <div className="blog-content">
            <Post posts={posts} />
        </div>
    );
}

export default Blog;
