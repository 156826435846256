import "./index.scss";

import {
    APPLICATION_TITLE,
    COOKIE_NAME,
    EDIT_ACCOUNT_REQUEST,
    EDIT_PWD_REQUEST,
    JWT_VALUE_IN_COOKIE,
    LOGOUT_MESSAGE,
    LOGOUT_REQUEST,
    PWD_UPDATE_GUIDELINES,
    REMOVE_ACCOUNT_MESSAGE,
    REMOVE_ACCOUNT_REQUEST
} from "../../tools";

import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {jwtDecode} from "jwt-decode";
import {useAccount} from "../../AccountContext";

import axios from "axios";
import Cookies from "js-cookie";

function EditAccount() {
    const { accountData, updateAccountData } = useAccount();

    const [showEditAccount, setShowEditAccount] = useState(false);
    const [showEditPassword, setShowEditPassword] = useState(false);

    const [editFirstName, setEditFirstName] = useState('');
    const [editLastName, setEditLastName] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const jwt = Cookies.get(COOKIE_NAME);
        if (!accountData && !jwt) {
            alert("Please Login first");
            navigateBackHome();
        } else if (!accountData && jwt) {
            updateAccountData(jwtDecode(jwt));
        }
    }, [accountData, updateAccountData, navigate]);

    function navigateBackHome() {
        navigate('/');
    }

    function editAccountInformation() {
        if (showEditAccount) {
            setEditFirstName('');
            setEditLastName('');
            setEditEmail('');
            setShowEditAccount(false);
        } else {
            setEditFirstName(accountData.firstName);
            setEditLastName(accountData.lastName);
            setEditEmail(accountData.email);
            setShowEditAccount(true);
        }
    }

    function editAccountPassword() {
        setShowEditPassword(!showEditPassword);
        setNewPassword('');
        setConfirmNewPassword('');
        if (!showEditPassword) {
            alert("Please type twice the new password")
        }
    }

    const handleEditAccount = async (event) => {
        event.preventDefault();
        const jwt = Cookies.get(COOKIE_NAME);
        if (jwt) {
            try {
                const response = await axios.patch(EDIT_ACCOUNT_REQUEST, {
                    id: accountData.id,
                    firstName: editFirstName,
                    lastName: editLastName,
                    email: editEmail,
                    role: null
                }, { headers: { "account_session": jwt } });

                const token = response.data;
                Cookies.set(COOKIE_NAME, token);
                updateAccountData(jwtDecode(token));

                editAccountInformation();
            } catch (error) {
                alert(error.response ? error.response.data : error.message);
            }
        } else {
            alert("Edit account issue: " + JWT_VALUE_IN_COOKIE);
        }
    };

    const handleEditPassword = async (event) => {
        event.preventDefault();
        const jwt = Cookies.get(COOKIE_NAME);
        if (jwt) {
            try {
                if (newPasswordFirstCheck()) {
                    await axios.patch(EDIT_PWD_REQUEST, {
                        oldPassword: oldPassword,
                        newPassword: newPassword
                    }, { headers: { "account_session": jwt } });

                    editAccountPassword();
                } else {
                    alert(PWD_UPDATE_GUIDELINES);
                }
            } catch (error) {
                alert(error.response ? error.response.data : error.message);
            }
        } else {
            alert("Edit password issue: " + JWT_VALUE_IN_COOKIE);
        }
    };

    function newPasswordFirstCheck() {
        return (oldPassword !== newPassword) && (newPassword === confirmNewPassword);
    }

    const handleLogout = async (event) => {
        event.preventDefault();
        if (window.confirm(LOGOUT_MESSAGE)) {
            const jwt = Cookies.get(COOKIE_NAME);
            if (jwt) {
                try {
                    await axios.post(LOGOUT_REQUEST, {}, { headers: { 'account_session': jwt } });
                } catch (error) {
                    alert(error.response ? error.response.data : error.message);
                }

                updateAccountData(null);
                Cookies.remove(COOKIE_NAME);
                navigate("/", {replace: true});
            } else {
                alert("Logout issue: " + JWT_VALUE_IN_COOKIE);
            }
        }
    };

    const removeAccount = async (event) => {
        event.preventDefault();
        if (window.confirm(REMOVE_ACCOUNT_MESSAGE)) {
            const jwt = Cookies.get(COOKIE_NAME);
            if (jwt) {
                try {
                    await axios.patch(REMOVE_ACCOUNT_REQUEST,
                        accountData.id,
                        { headers: { 'account_session': jwt }
                        });

                    updateAccountData(null);
                    Cookies.remove(COOKIE_NAME);
                    navigate("/", { replace: true });
                } catch (error) {
                    alert(error.response ? error.response.data : error.message);
                }
            } else {
                alert("Remove account issue: " + JWT_VALUE_IN_COOKIE);
            }
        }
    }

    return (
        <>
            <h1 onClick={navigateBackHome}>{APPLICATION_TITLE}</h1>
            {showEditAccount ? (
                <>
                    <form onSubmit={handleEditAccount}>
                        <div>
                            <label htmlFor="firstName">FirstName:</label>
                            <input
                                type="text"
                                value={editFirstName}
                                onChange={(e) => setEditFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="lastName">LastName:</label>
                            <input
                                type="text"
                                value={editLastName}
                                onChange={(e) => setEditLastName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                value={editEmail}
                                onChange={(e) => setEditEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit">Submit</button>
                        <button type="button" onClick={editAccountInformation}>Cancel</button>
                        <button type="button" onClick={removeAccount} className="remove-account-btn">Remove Account</button>
                    </form>
                </>
            ) : showEditPassword ? (
                <>
                    <p>AccountId: {accountData.id}</p>
                    <p>FirstName: {accountData.firstName}</p>
                    <p>LastName: {accountData.lastName}</p>
                    <br/>
                    <form onSubmit={handleEditPassword}>
                        <div>
                            <label htmlFor="password">Old Password:</label>
                            <input
                                type="password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="password">New Password:</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="password">New Password:</label>
                            <input
                                type="password"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit">Submit</button>
                        <button type="button" onClick={editAccountPassword}>Cancel</button>
                    </form>
                </>
            ) : (
                <div className="account-container">
                    {accountData && (
                        <div className="account-data">
                            <p>{accountData.id}</p>
                            <p>{accountData.firstName}</p>
                            <p>{accountData.lastName}</p>
                            <br />
                        </div>
                    )}
                    <button type="button" onClick={editAccountInformation}>Edit Account</button>
                    <button type="button" onClick={editAccountPassword}>Edit Password</button>
                    {accountData && accountData.accountRole === "ADMIN" && (
                        <>
                            <br />
                            <br />
                            {location.pathname === '/blogs' ? (
                                <button type="button"
                                        onClick={() => navigate('/accounts')}>
                                    Manage Accounts
                                </button>
                            ) : (
                                <button type="button"
                                        onClick={() => navigate('/blogs')}>
                                    Manage Posts
                                </button>
                            )}
                        </>
                    )}
                    <div className="logout-container-edit">
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default EditAccount;
