import "./index.scss";

import {
    ACCOUNTS_CONNECTED_REQUEST,
    BASE_URL,
    COOKIE_NAME,
    GLOBAL_CHAT_REQUEST,
    JWT_VALUE_IN_COOKIE,
    MAX_MESSAGE_LENGTH,
    MAX_WORD_LENGTH,
    MYCHATROOMS_REQUEST,
    WEB_SOCKET_URL,
    WS_CONNECTION_ERROR
} from "../../tools";

import {useAccount} from "../../AccountContext";

import React, {useEffect, useRef, useState} from 'react';
import {Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';

import SockJS from 'sockjs-client';
import Cookies from "js-cookie";
import axios from "axios";
import Stomp from 'stompjs';

import SearchAccountPopup from "../SearchAccountPopup";

import user_icon from '../../assets/img/account_icon.png';
import search_icon from '../../assets/img/search_icon.png';
import close_icon from '../../assets/img/close_icon.png';
import {jwtDecode} from "jwt-decode";

let stompClient = null;

const ChatBox = options => {
    let socket = new SockJS(WEB_SOCKET_URL);
    const jwt = Cookies.get(COOKIE_NAME);

    const { accountData, isConnected } = useAccount();
    const [users, setUsers] = useState([]);

    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);
    const popupId = open ? 'simple-popper' : undefined;

    const [selectedUserId, setSelectedUserId] = useState("ROOM");

    const [messageData, setMessageData] = useState("");
    const [messages, setMessages] = useState([]);
    const [globalMessages, setGlobalMessages] = useState([])
    const [isNewGlobalMsg, setIsNewGlobalMsg] = useState(false);

    const [isChatRoom, setIsChatRoom] = useState(false);
    const [isSearchUser, setIsSearchUser] = useState(false);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages, globalMessages]);

    useEffect(() => {
        const client = Stomp.over(socket);

        if (isConnected && accountData !== null) {
            stompClient = client;
            connect();
            getMyChatRooms().then(r => {});

        }
    }, [isConnected, accountData]);

    const connect = () => {
        stompClient = Stomp.over(socket);
        stompClient.connect({}, onConnected, onError);
    };

    const onError = (err) => {
        console.error(WS_CONNECTION_ERROR + err);
    }

    const onConnected = () => {
        stompClient.subscribe(`/user/${accountData.id}/queue/messages`, onMessageReceived);
        stompClient.subscribe(`/topic/public`, onGlobalMessageReceived);
        findAndDisplayConnectedUsers().then(r => {});
    };

    const onGlobalMessageReceived = async (payload) => {
    const message = JSON.parse(payload.body);

        if (message.hasOwnProperty("status")) {
            if (message.status === "ONLINE" || message.status === "OFFLINE") {
                if (message.hasOwnProperty("jwt")) {
                    const user = jwtDecode(message.jwt);
                    if (message.status === "ONLINE") {
                        if (!users.some(u => u.id === user.id)) {
                            const newUser = { ...user, unreadMsgs: false };
                            setUsers(prevUsers => [...prevUsers, newUser]);
                        }
                    } else { // OFFLINE
                        const updatedUsers = users.filter(u => u.id !== user.id);
                        setUsers(updatedUsers);
                    }
                } else {
                    console.error("Missing property JWT in message");
                }
            }
        } else {
            setGlobalMessages(prevMessages => [...prevMessages, message]);
            if (accountData.id !== message.senderId) {
                setIsNewGlobalMsg(true);
            }
        }
    }


    const onMessageReceived = async (payload) => {
        try {
            const message = JSON.parse(payload.body);
            if (selectedUserId === message.senderId) {
                setMessages(prevMessages => [...prevMessages, message]);
            } else {
                setUsers(prevUsers => {
                    return prevUsers.map(user => {
                        if (user.id === message.senderId) {
                            return { ...user, unreadMsgs: true };
                        }
                        return user;
                    });
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getMyChatRooms = async () => {
        if (jwt !== undefined) {
            try {
                const response = await axios.get(MYCHATROOMS_REQUEST, {
                    headers: {
                        "account_session": jwt
                    }
                });
                const updatedUsers = response.data.map(user => ({ ...user, unreadMsgs: false }));
                setUsers(updatedUsers);
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("Get MyChatRooms issue: "+ JWT_VALUE_IN_COOKIE);
        }
    }

    const findAndDisplayConnectedUsers = async () => {
        if (jwt !== undefined) {
            try {
                const response = await axios.get(ACCOUNTS_CONNECTED_REQUEST, {
                    headers: {
                        "account_session": jwt
                    }
                });

                response.data.map(user => {
                    if (!users.some(u => u.id === user.id)) {
                        let newUser = {...user, unreadMsgs: false};
                        setUsers([...users, newUser]);
                    }
                });
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("Get connected account issue: " + JWT_VALUE_IN_COOKIE)
        }
    };

    const formatMessage = (message) => {
        const messageLines = message.split('\n');
        let formatedMessage = '';

        for (let i = 0; i < messageLines.length; i++) {
            let line = messageLines[i];
            if (/^\s*$/m.test(line)) {
                continue;
            }
            while (line.length > MAX_MESSAGE_LENGTH) {
                let lastWhitespaceIndex = line.lastIndexOf(' ', MAX_MESSAGE_LENGTH);

                if (lastWhitespaceIndex !== -1) {
                    formatedMessage += line.substring(0, lastWhitespaceIndex) + '\n';
                    line = line.substring(lastWhitespaceIndex + 1);
                } else {
                    formatedMessage += line.substring(0, MAX_WORD_LENGTH) + '-\n';
                    line = line.substring(MAX_WORD_LENGTH);
                }
            }
            formatedMessage += line + '\n';
        }
        return formatedMessage;
    }

    const sendMessage = (event) => {
        event.preventDefault();
        if (stompClient) {
            const formatedMessage = formatMessage(messageData)
            const chatMessage = {
                senderId: accountData.id,
                recipientId: selectedUserId,
                content: formatedMessage,
                timestamp: new Date()
            };
            stompClient.send("/app/chat", {}, JSON.stringify(chatMessage));
            setMessages(prevMessages => [...prevMessages, chatMessage]);
            setMessageData('');
        }
    };

    const sendGlobalMessage = (event) => {
        event.preventDefault();
        if (stompClient) {
            const formatedMessage = formatMessage(messageData)
            const chatMessage = {
                author: accountData.firstName + " " + accountData.lastName,
                senderId: accountData.id,
                content: formatedMessage,
                timestamp: new Date()
            };
            stompClient.send('/app/chat/global', {}, JSON.stringify(chatMessage));
            setMessageData('');
        }
    }

    const userItemClick = (event) => {
        document.querySelectorAll('.user-item').forEach(item => {
            item.classList.remove('active');
        });
        const clickedUser = event.currentTarget;
        clickedUser.classList.add('active');
        setIsChatRoom(false);

        const clickedUserId = clickedUser.id
        setSelectedUserId(clickedUserId);
        fetchAndDisplayUserChat(clickedUserId).then(r => {});
    };

    const chatRoomClick = (event) => {
        document.querySelectorAll('.user-item').forEach(item => {
            item.classList.remove('active');
        });
        const clickedUser = event.currentTarget;
        clickedUser.classList.add('active');
        setIsChatRoom(true);

        setSelectedUserId("ROOM");
        setIsNewGlobalMsg(false);

        fetchAndDisplayGlobalChat().then(r => {});
    }

    const fetchAndDisplayUserChat = async (clickedUserId) => {
        if (jwt !== undefined) {
            if (accountData) {
                try {
                    setUsers(prevUsers => {
                        return prevUsers.map(user => {
                            if (user.id === clickedUserId) {
                                return {...user, unreadMsgs: false};
                            }
                            return user;
                        });
                    });

                    const response = await axios.get(`${BASE_URL}/messages/${accountData.id}/${clickedUserId}`,
                        {
                            headers: {
                                "account_session": jwt
                            }
                        });
                    const userChat = response.data;
                    setMessages(userChat);
                } catch (error) {
                    console.error(error);
                }
            }
        } else {
            alert("Get user chat issue: " + JWT_VALUE_IN_COOKIE)
        }
    };

    const fetchAndDisplayGlobalChat = async () => {
        if (jwt !== undefined) {
            try {
                const response = await axios.get(GLOBAL_CHAT_REQUEST,
                    {
                        headers: {
                            "account_session": jwt
                        }
                    });
                const globalChat = response.data;
                setGlobalMessages(globalChat);
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("Get global chat issue: " + JWT_VALUE_IN_COOKIE)
        }
    }

    const handleSearchUserClick = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setIsSearchUser(!isSearchUser);
    };

    const addUser = (userId) => {
        getUserData(userId).then(r => {});
        handleSearchUserClick();
    }

    const getUserData = async (userId) => {
        if (jwt !== undefined) {
            try {
                const response = await axios.get(`${BASE_URL}/api/account/id?id=${userId}`,
                    {
                        headers: {
                            "account_session": jwt
                        }
                    });
                const user = jwtDecode(response.data);
                if (!users.some(u => u.id === user.id)) {
                    let newUser = { ...user, unreadMsgs: false };
                    setUsers([...users, newUser]);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("Get user data issue: " + JWT_VALUE_IN_COOKIE)
        }
    }

    return (
        <div className="chat-box-container" >
            { accountData ? (
                <div className="member-list">
                    <h2 className="chat-box-title">ChatBox</h2>
                    <hr/>
                    <li className="user-item" onClick={chatRoomClick}>
                        <img src={user_icon} alt="CHATROOM"
                             className={isNewGlobalMsg ? 'user-avatar-unread-msg' : 'user-avatar'}/>
                        <span className="chat-room-name">ROOM</span>
                    </li>
                    <hr/>
                    <h2 className="search-user-container">
                        { isSearchUser ? (
                            <li className="close-search-user" onClick={handleSearchUserClick}>
                                <img src={close_icon} alt="CLOSE" className="user-avatar"/>
                                <span className="search-name">Close</span>
                            </li>
                        ) : (
                            <li className="open-search-user" onClick={handleSearchUserClick}>
                                <img src={search_icon} alt="SEARCH" className="user-avatar"/>
                                <span className="search-name">Search Users</span>
                            </li>
                        )}
                    </h2>
                    <BasePopup id={popupId} open={open} anchor={anchor}>
                        <SearchAccountPopup jwtValue={jwt} addAccount={addUser} />
                    </BasePopup>
                    <hr/>
                    <ul className="scrollable-members">
                        { users.map(user => (
                            <li key={user.firstName} className="user-item" id={user.id} onClick={userItemClick} >
                                <img src={user_icon} alt={user.firstName}
                                     className={user.unreadMsgs ? 'user-avatar-unread-msg' : 'user-avatar'}/>
                                <span className="user-firstName">{user.firstName}</span>
                            </li>
                            )
                        )}
                    </ul>
                </div>
            ) : (
                <div className="member-list">
                    <h2 className="chat-box-title">ChatBox</h2>
                    <hr/>
                </div>
            )}
            { isChatRoom ? (
                <div className="chat-content">
                    <div className="chat-messages">
                        { globalMessages.map((message, index) => (
                            <div key={index}
                                 className={`message ${message.senderId === accountData.id ? 'sender' : 'receiver'}`}>
                                <div className="message-data">
                                    <p className='message-author'>{message.author}</p>
                                    <pre>{message.content}</pre>
                                </div>
                            </div>
                            )
                        )}
                        <div ref={messagesEndRef}/>
                    </div>
                    <div className={selectedUserId ? 'send-message' : 'hidden'} >
                        <textarea className="new-message"
                                  placeholder="Type the message"
                                  value={messageData}
                                  onChange={(e) => setMessageData(e.target.value)}
                                  required
                        />
                        <button className="send-button"
                                type="button"
                                onClick={sendGlobalMessage}> SEND
                        </button>
                    </div>
                </div>
            ) : (
                <div className="chat-content">
                    <div className="chat-messages">
                        { messages.map((message, index) => (
                            <div key={index}
                                 className={`message ${message.senderId === accountData.id ? 'sender' : 'receiver'}`}>
                                <div className="message-data">
                                    <pre>{message.content}</pre>
                                </div>
                            </div>
                            )
                        )}
                        <div ref={messagesEndRef}/>
                    </div>
                    <div className={selectedUserId ? 'send-message' : 'hidden'}>
                        <textarea className="new-message"
                                  placeholder="Type the message"
                                  value={messageData}
                                  onChange={(e) => setMessageData(e.target.value)}
                                  required
                        />
                        <button className="send-button"
                                type="button"
                                onClick={sendMessage}> SEND
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ChatBox;
