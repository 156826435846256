import "./index.scss";

import {
    BASE_URL,
    COOKIE_NAME,
    EVENT_SOURCE_POSTS_REQUEST,
    JWT_VALUE_IN_COOKIE,
    MY_POSTS_REQUEST,
    NEW_POST_REQUEST,
    REMOVE_POST_MESSAGE
} from '../../tools';

import Post from '../Post';

import React, {useEffect, useState} from 'react';

import axios from 'axios';
import Cookies from "js-cookie";

const MyBlogs = () => {
    const jwt = Cookies.get(COOKIE_NAME);

    const [posts, setPosts] = useState([]);

    const [newPostTitle, setNewPostTitle] = useState('');
    const [newPostBody, setNewPostBody] = useState('');

    const [showNewPostForm, setShowNewPostForm] = useState(false);

    useEffect(() => {
        getMyPosts().then(r => {});

        const eventSource = new EventSource(EVENT_SOURCE_POSTS_REQUEST);

        eventSource.addEventListener("newPost", (event) => {
            const newPost = JSON.parse(event.data);
            setPosts((prevPosts) => [...prevPosts, newPost]);
        });

        eventSource.addEventListener("removePost", (event) => {
            const removedPost = JSON.parse(event.data);
            setPosts((prevPosts) => prevPosts.filter(post => post.id !== removedPost.id));
        });

        eventSource.addEventListener("updatePost", (event) => {
            const updatedPost = JSON.parse(event.data);
            setPosts((prevPosts) => prevPosts.map(post => post.id === updatedPost.id ? updatedPost : post));
        });

        return () => {
            eventSource.close();
        };
    }, []);

    const getMyPosts = async () => {
        if (jwt !== undefined) {
            try {
                const response = await axios.get(MY_POSTS_REQUEST, {
                    headers: {
                        "account_session": jwt
                    },
                });
                setPosts(response?.data || []);
            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    alert(error.response.data);
                } else {
                    console.error(error);
                }
            }
        } else {
            alert("Get my posts issue: " + JWT_VALUE_IN_COOKIE);
        }
    };

    const handleEdit = async (postId, editPostTitle, editPostBody) => {
        if (jwt !== undefined) {
            try {
                await axios.patch(`${BASE_URL}/api/posts/${postId}`, {
                        title: editPostTitle,
                        body: editPostBody
                    },
                    {
                        headers: {
                            "account_session": jwt
                        }
                    });

                await getMyPosts();

                setNewPostTitle('');
                setNewPostBody('');

                setShowNewPostForm(false)
            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    alert(error.response.data);
                } else {
                    console.error(error);
                }
            }
        } else {
            alert("Edit post issue: " + JWT_VALUE_IN_COOKIE);
        }
    };

    const handleRemove = async (postId) => {
        if (window.confirm(REMOVE_POST_MESSAGE)) {
            if (jwt !== undefined) {
                try {
                    await axios.get(`${BASE_URL}/api/posts/${postId}/delete`, {
                        headers: {
                            "account_session": jwt
                        },
                    });

                    await getMyPosts();
                } catch (error) {
                    if (error.response && error.response.status !== 200) {
                        alert(error.response.data);
                    } else {
                        console.error(error);
                    }
                }
            } else {
                alert("Remove post issue: " + JWT_VALUE_IN_COOKIE);
            }
        }
    };

    const handleNew = async (event) => {
        event.preventDefault();
        if (jwt !== undefined) {
            try {
                await axios.post(NEW_POST_REQUEST, {
                        title: newPostTitle,
                        body: newPostBody
                    },
                    {
                        headers: {
                            "account_session": jwt
                        }
                    });

                await getMyPosts();

                setNewPostTitle('');
                setNewPostBody('');

                setShowNewPostForm(false)
            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    alert(error.response.data);
                } else {
                    console.error(error);
                }
            }
        } else {
            alert("New post issue: " + JWT_VALUE_IN_COOKIE);
        }
    };

    function handleShowNewPost() {
        setShowNewPostForm(!showNewPostForm);
        setNewPostTitle('');
        setNewPostBody('');
    }

    return (
        <div className="myBlog-container">
            { showNewPostForm ? (
                <form onSubmit={handleNew}>
                    <div className="new-post-container">
                        <input
                            className="new-post-title"
                            type="text"
                            placeholder="Post Title"
                            value={newPostTitle}
                            onChange={(e) => setNewPostTitle(e.target.value)}
                            required
                        />
                        <textarea
                            className="new-post-body"
                            placeholder="Post Body"
                            value={newPostBody}
                            onChange={(e) => setNewPostBody(e.target.value)}
                            required
                        />
                        <button type="submit">Post</button>
                        <button type="button" onClick={handleShowNewPost}>Cancel</button>
                    </div>
                    <br/>
                </form>
            ) : (
                <button onClick={handleShowNewPost}>New Post</button>
            )}
            <div className="scrollable-content">
                <Post posts={posts} onEdit={handleEdit} onRemove={handleRemove} />
            </div>
        </div>
    );
};

export default MyBlogs;
