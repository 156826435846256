import './index.scss';

import React, {useState} from "react";
import axios from "axios";

import {BASE_URL, JWT_VALUE_IN_COOKIE} from "../../tools";
import account_icon from "../../assets/img/account_icon.png";

const SearchAccountPopup = (props) => {
    const jwt = props.jwtValue;
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = async (input) => {
        if (jwt !== null) {
            try {
                setSearchTerm(input);
                const response = await axios.get(`${BASE_URL}/api/account/search?searchTerm=${input}`,
                    {
                        headers: {
                            "account_session": jwt
                        }
                    });
                setSearchResults(response.data);
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("Search account issue: " + JWT_VALUE_IN_COOKIE);
        }
    };

    const selectAccount = (event) => {
        const clickedAccount = event.currentTarget;
        props.addAccount(clickedAccount.id);
    }

    return (
        <div className="popup-container">
            <input
                type="text"
                className="input-container"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Enter name and surname"
            />
            <ul className="account-popup-container">
                { searchResults.map(account => (
                    <li key={account.firstName} className="account-item-popup" id={account.id} onClick={selectAccount}>
                        <img src={account_icon} alt={account.firstName} className='account-avatar-popup' />
                        <span className="account-data">{account.firstName} {account.lastName}</span>
                    </li>
                    )
                )}
            </ul>
    </div>
    );
};

export default SearchAccountPopup;
