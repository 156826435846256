import './index.scss';

import React, {useState} from 'react';

import {formatDateTime} from "../../tools";

const Post = ({ posts, onEdit, onRemove }) => {
    const [editPostId, setEditPostId] = useState(null);
    const [editPostTitle, setEditPostTitle] = useState('');
    const [editPostBody, setEditPostBody] = useState('');

    const enterEditMode = (postId, title, body) => {
        setEditPostId(postId);
        setEditPostTitle(title);
        setEditPostBody(body);
    };

    const exitEditMode = () => {
        setEditPostId(null);
        setEditPostTitle('');
        setEditPostBody('');
    };

    const handleSubmit = () => {
        if (editPostId !== null) {
            onEdit(editPostId, editPostTitle, editPostBody);
            exitEditMode();
        }
    };

    const sortedPosts = posts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    return (
        <div className="post-container">
            {sortedPosts.map((post, idx) => (
                <div className="post" key={idx}>
                    {editPostId === post.id ? (
                        <form onSubmit={handleSubmit}>
                            <div className="edit-post-container">
                                <input
                                    className="edit-post-title"
                                    type="text"
                                    defaultValue={post.title}
                                    value={editPostTitle}
                                    onChange={(e) => setEditPostTitle(e.target.value)}
                                    required
                                />
                                <textarea
                                    className="edit-post-body"
                                    defaultValue={post.body}
                                    value={editPostBody}
                                    onChange={(e) => setEditPostBody(e.target.value)}
                                    required
                                />
                                <button type="submit">Save</button>
                                <button type="button" onClick={exitEditMode}>Cancel</button>
                            </div>
                        </form>
                    ) : (
                        <div className="post-data-container">
                            <div className="post-title">{post.title}</div>
                            <div className="post-body">{post.body}</div>
                            {post.createdAt === post.updatedAt ? (
                                <div className="post-date-and-time">
                                    <div className="post-created-at">Created at: {formatDateTime(post.createdAt)}</div>
                                </div>
                            ) : (
                                <div className="post-date-and-time">
                                    <div className="post-created-at">Created at: {formatDateTime(post.createdAt)}</div>
                                    <div className="post-updated-at">Updated at: {formatDateTime(post.updatedAt)}</div>
                                </div>
                            )}
                            <div className="post-account-data">By: {post.accountPseudo}</div>
                            {(onEdit && onRemove) && (
                                <div>
                                    <button onClick={() => enterEditMode(post.id, post.title, post.body)}>Edit</button>
                                    <button onClick={() => onRemove(post.id)}>Remove</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Post;
