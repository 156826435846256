import "./index.scss";

import React, {useEffect, useState} from 'react';
import Cookies from "js-cookie";
import Account from "../Account";
import axios from "axios";
import {
    ALL_ACCOUNTS_REQUEST,
    COOKIE_NAME,
    EDIT_ACCOUNT_REQUEST,
    JWT_VALUE_IN_COOKIE,
    REMOVE_ACCOUNT_MESSAGE,
    REMOVE_ACCOUNT_REQUEST
} from '../../tools';

const MyAccounts = () => {
    const jwt = Cookies.get(COOKIE_NAME);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        getAllAccounts().then(r => {});
    }, []);

    const getAllAccounts = async () => {
        if (jwt !== undefined) {
            try {
                const response = await axios.get(ALL_ACCOUNTS_REQUEST, {
                    headers: {
                        "account_session": jwt
                    },
                });
                setAccounts(response?.data || []);
            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    alert(error.response.data);
                } else {
                    console.error(error);
                }
            }
        } else {
            alert("Get my posts issue: " + JWT_VALUE_IN_COOKIE);
        }
    }

    const handleRemove = async (userId) => {
        if (window.confirm(REMOVE_ACCOUNT_MESSAGE)) {
            if (jwt !== undefined) {
                try {
                    await axios.patch(REMOVE_ACCOUNT_REQUEST,
                        userId,
                        {
                            headers: {
                                'Content-Type': 'text/plain',
                                'account_session': jwt
                            }
                        });

                    await getAllAccounts();
                } catch (error) {
                    if (error.response && error.response.status !== 200) {
                        alert(error.response.data);
                    } else {
                        console.error(error);
                    }
                }
            } else {
                alert("Remove user issue: " + JWT_VALUE_IN_COOKIE);
            }
        }
    }

    const handleEdit = async (editUserId, firstName, lastName, email, role) => {
        if (jwt !== undefined) {
            try {
                await axios.patch(EDIT_ACCOUNT_REQUEST, {
                    id: editUserId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    role: role
                }, { headers: { "account_session": jwt } });

                await getAllAccounts();
            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    alert(error.response.data);
                } else {
                    console.error(error);
                }
            }
        } else {
            alert("Remove user issue: " + JWT_VALUE_IN_COOKIE);
        }
    }

    return (
        <div className="full-height-container">
            <div className="scrollable-content">
                <Account accounts={accounts} onRemoveAccount={handleRemove} onEditAccount={handleEdit}/>
            </div>
        </div>
    );
};

export default MyAccounts;
