import './index.scss';

import {useState} from "react";

const Account = ({ accounts, onRemoveAccount, onEditAccount }) => {
    const [editAccountId, setEditAccountId] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    const enterEditMode = (account) => {
        setEditAccountId(account.id);
        setFirstName(account.firstName);
        setLastName(account.lastName);
        setEmail(account.email);
        setRole(account.role);
    }

    const closeAccountEditMode = () => {
        setEditAccountId(null);
        setFirstName('');
        setLastName('');
        setEmail('');
        setRole('');
    }

    const handleEditAccount = () => {
        onEditAccount(editAccountId, firstName, lastName, email, role);
        closeAccountEditMode();
    }

    return (
        <table className="account-table">
            <thead>
            <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {accounts.map((account) => (
                <tr key={account.id}>
                    {editAccountId === account.id ? (
                        <>
                            <td>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </td>
                            <td>
                                <select
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option value="USER">USER</option>
                                    <option value="ADMIN">ADMIN</option>
                                </select>
                            </td>
                            <td>
                                <button className="btn-save" onClick={handleEditAccount}>SAVE</button>
                                <button className="btn-close" onClick={closeAccountEditMode}>CLOSE</button>
                            </td>
                        </>
                    ) : (
                        <>
                            <td>{account.firstName}</td>
                            <td>{account.lastName}</td>
                            <td>{account.email}</td>
                            <td>{account.role}</td>
                            <td>
                                <button className="btn-edit" onClick={() => enterEditMode(account)}>EDIT</button>
                                <button className="btn-remove" onClick={() => onRemoveAccount(account.id)}>REMOVE</button>
                            </td>
                        </>
                    )}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default Account;
