import React, {createContext, useContext, useState} from 'react';

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
    const [accountData, setAccountData] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    const updateAccountData = (newAccountData) => {
        setAccountData(newAccountData);
        setIsConnected(newAccountData !== null);
    };

    return (
        <AccountContext.Provider value={{ accountData, updateAccountData, isConnected }}>
            {children}
        </AccountContext.Provider>
    );
};

export const useAccount = () => useContext(AccountContext);
