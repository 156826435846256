export const APPLICATION_TITLE = "My Social Network";
export const COOKIE_NAME = "account_session";

export const MAX_WORD_LENGTH = 15;
export const MAX_MESSAGE_LENGTH = 35;

// Error Messages
export const JWT_VALUE_IN_COOKIE = "could not load the account_session cookie from the cookies";
export const PWD_UPDATE_GUIDELINES = "New Password can not be the same as the new one\nNew password fields has to be the same";
export const PWD_NO_MATCH = "Passwords don't match";
export const WS_CONNECTION_ERROR = 'Could not connect to WebSocket server.\n';

// HTTP Requests
export const BASE_URL = 'https://msn.luctatu.fr';
export const WEB_SOCKET_URL = `${BASE_URL}/ws`;
export const EDIT_PWD_REQUEST = `${BASE_URL}/api/account/edit/pwd`;
export const EDIT_ACCOUNT_REQUEST = `${BASE_URL}/api/account/edit`;
export const FORGOT_PWD_ACCOUNT_REQUEST = `${BASE_URL}/api/account/forgot/pwd`;
export const NEW_PWD_ACCOUNT_REQUEST = `${BASE_URL}/api/account/new/pwd`;
export const LOGOUT_REQUEST = `${BASE_URL}/api/logout`;
export const REMOVE_ACCOUNT_REQUEST = `${BASE_URL}/api/account/remove`;
export const LOGIN_REQUEST = `${BASE_URL}/api/login`;
export const REGISTER_REQUEST = `${BASE_URL}/api/register`;
export const ALL_POSTS_REQUEST = `${BASE_URL}/api/posts/all`;
export const MY_POSTS_REQUEST = `${BASE_URL}/api/posts/`;
export const EVENT_SOURCE_POSTS_REQUEST = `${BASE_URL}/api/stream`;
export const MYCHATROOMS_REQUEST = `${BASE_URL}/api/account/mychatrooms`;
export const ACCOUNTS_CONNECTED_REQUEST = `${BASE_URL}/api/account/connected`;
export const GLOBAL_CHAT_REQUEST = `${BASE_URL}/messages/global`;
export const NEW_POST_REQUEST = `${BASE_URL}/api/posts/new`;
export const ALL_ACCOUNTS_REQUEST = `${BASE_URL}/api/account/all`;

// Info Messages
export const LOGOUT_MESSAGE = "Are you sure you want to logout?";
export const REMOVE_ACCOUNT_MESSAGE = "Are you sure you want to remove the account ?\nThe account will be deleted for ever";
export const REMOVE_POST_MESSAGE = "Are you sure you want to remove the post?";
export const EMAIL_CONFIRMATION_MESSAGE = "Please verify your mailbox for full registration";
export const RESET_PASSWORD_MESSAGE = "Please check your mailbox to reset your account password.";
export const PASSWORD_CHANGE_SUCCESS_MESSAGE = "MyAccount password modified successfully!";

export const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const hour = dateTime.getHours() % 12 || 12;
    const minute = dateTime.getMinutes().toString().padStart(2, '0');
    const ampm = dateTime.getHours() >= 12 ? ' pm' : ' am';
    const month = monthNames[dateTime.getMonth()];
    const date = dateTime.getDate();
    const year = dateTime.getFullYear();

    return `${hour}:${minute}${ampm} on ${month} ${date} ${year}`;
};
