import './index.scss';

import EditAccount from '../../components/EditAccount';
import ChatBox from '../../components/ChatBox';
import MyAccounts from '../../components/MyAccounts';

import {useAccount} from '../../AccountContext';

import React, {useEffect, useState} from 'react';
import Loader from "react-loaders";

const ManageAccounts = () => {
    const { accountData } = useAccount();
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsActive(false);
        }, 2000)
    })

    return (
        <>
            {!isActive? (
                <div className="manage-accounts-container">
                    <div className="left-column">
                        <EditAccount accountData={accountData} />
                    </div>

                    <div className="center-column">
                        <MyAccounts accountData={accountData} />
                    </div>

                    <div className="right-column">
                        <ChatBox accountData={accountData} />
                    </div>
                </div>
            ) : (
                <Loader type="ball-clip-rotate" active={isActive} />
            )};
        </>
    );
}

export default ManageAccounts;
