import './index.scss';

import MyAccount from '../../components/MyAccount';
import Blog from '../../components/Blog';
import ChatBox from '../../components/ChatBox';

import {useAccount} from '../../AccountContext';

import React, {useEffect, useState} from 'react';
import Loader from 'react-loaders';

const Home = () => {
    const { accountData } = useAccount();
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsActive(false);
        }, 2000)
    })

    return (
        <>
            {!isActive? (
                <div className="home-container">
                    <div className="left-column">
                        <MyAccount accountData={accountData} />
                    </div>

                    <div className="center-column">
                        <Blog accountData={accountData} />
                    </div>

                    <div className="right-column">
                        <ChatBox accountData={accountData} />
                    </div>
                </div>
            ) : (
                <Loader type="ball-clip-rotate" active={isActive} />
            )};
    </>
    );
}

export default Home;
