import './App.scss';

import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {AccountProvider} from './AccountContext';

import Home from './pages/Home';
import ManageBlogs from './pages/ManageBlogs';
import ManageAccounts from "./pages/ManageAccounts";

function App() {
    return (
        <AccountProvider>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/blogs" element={<ManageBlogs />} />
                <Route path="/accounts" element={<ManageAccounts />} />
            </Routes>
        </AccountProvider>
    );
}

export default App;
